import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../storage/local-storage.service';
import { Keys } from '../../sharedkernal/keys';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private localStorageService: LocalStorageService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userToken = this.localStorageService.read(Keys.tokenLocalStorageKey);
        let request = req;
        if (userToken) {
            request = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${userToken}`),
            });
        }
        return next.handle(request);
    }

}