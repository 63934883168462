import { RolesNames } from './enums/roles-names.enum';

export default class UserTokenData {
    email: string;
    name: string;
    token: string; 
    role: RolesNames;

    constructor(init: UserTokenData) {
        Object.assign(this, init);
    }

}