/**
 * Used to store and retrieve data from the local storage
 */
import jwt_decode from "jwt-decode";
import { RolesNames } from 'src/app/models/accounts/enums/roles-names.enum';
import LayoutData from 'src/app/models/general/layout/layout-data.model';
import { Keys } from 'src/app/sharedkernal/keys';
import UserTokenData from '../../models/accounts/user-token-data.model';

export class LocalStorageService {

    write(key: string, value: any): boolean {
        try {
            if (value) {
                value = JSON.stringify(value);
            }
            localStorage.setItem(key, value);
            return true;
        } catch (e) {
            console.log(e);
            //Log exception in the future
            return false;
        }
    }

    read<T>(key: string): T {
        let value: string = localStorage.getItem(key);

        if (value && value != "undefined" && value != "null") {
            return <T>JSON.parse(value);
        }

        return null;
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    writeLayoutData(layoutData: LayoutData) {
        this.write(Keys.layoutStorageKey, layoutData);

    }
    getLayoutData(): LayoutData {
        return this.read<LayoutData>(Keys.layoutStorageKey);
    }


    getTokenData(): UserTokenData {
        const token: string = localStorage.getItem("Token");
        const decodedToken = jwt_decode(token);
        return new UserTokenData({
            email: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            name: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
            role: RolesNames[decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] as string],
            token: ''
        });
    }

    /**
     * Used to check if the key has a value inside the local storage
     */
    isKeyExists(key: string): boolean {
        return localStorage.getItem(key) != null;
    }

}