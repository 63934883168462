import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import DataOperationResult from 'src/app/models/base/data-operation-result';
import OperationResult from 'src/app/models/base/operation-result';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import LayoutData from 'src/app/models/general/layout/layout-data.model';
import YearModel from 'src/app/models/general/year/year.model';
import { environment } from 'src/environments/environment';
import { BetaService } from './base/beta.service';

@Injectable({
    providedIn: 'root'
})
export class ImagesService extends BetaService {
    constructor(private httpClient: HttpClient) {
        super();
    }
    static controllerName = 'images';


    uploadImage(images: FileList) {
        const formData: FormData = new FormData();
        Array.from(images).forEach(image => {
            formData.append('fileKey', image, image.name);
        });
        return this.httpClient.post<DataOperationResult<GenericOperationResult, Array<string>>>
            (`${environment.apiUrl}${ImagesService.controllerName}/`,
                formData, {
                reportProgress: true,
                observe: 'events',

            })
            .pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { status: 'progress', response: progress };
                    case HttpEventType.Response:
                        return { status: 'done', response: event.body, entity: event.body.entity };
                    default:
                        return { status: 'error', response: event.type };
                }
            }));
    }
}
