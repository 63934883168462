import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataOperationResult from 'src/app/models/base/data-operation-result';
import OperationResult from 'src/app/models/base/operation-result';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import { environment } from 'src/environments/environment';
import { BetaService } from './base/beta.service';
import Slider from '../models/sliders/slider.model';

@Injectable({
    providedIn: 'root'
})
export class SlidersService extends BetaService {
    static controllerName = 'sliders';
    constructor(private httpClient: HttpClient) {
        super();
    }


    getSliders(): Promise<DataOperationResult<GenericOperationResult, Array<Slider>>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Array<Slider>>>
                (`${environment.apiUrl}${SlidersService.controllerName}/`,
                    {
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }


    saveSlider(slider: Slider): Promise<DataOperationResult<GenericOperationResult, string>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('post', `${environment.apiUrl}${SlidersService.controllerName}/`,
                    {
                        body: slider
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

    deleteSlider(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${SlidersService.controllerName}/`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
}