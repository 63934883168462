import { Injectable } from '@angular/core';
import Product from 'src/app/models/products/product.model';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    constructor() {

    }

    validateProduct(product: Product): Array<string> {
        let errors = new Array<string>();
        if (!product.subsectionId)
            errors.push('Please select subsection');
        if (!product.brandId)
            errors.push('Please select brand');

        if (product.isAr && !product.descriptionAr)
            errors.push('Please enter Arabic title');
        if (product.isEn && !product.descriptionEn)
            errors.push('Please enter English title');
        if (product.isTr && !product.descriptionTr)
            errors.push('Please enter Turkish title');
        return errors;
    }

}
