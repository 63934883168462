import { Component, Directive } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AbstractLogger } from 'angular-logz-io';
import { RolesNames } from 'src/app/models/accounts/enums/roles-names.enum';
import UserTokenData from 'src/app/models/accounts/user-token-data.model';
import DocumentItemViewType from 'src/app/models/enums/sheet-question-view-type.enum';
import SheetType from 'src/app/models/enums/sheet-type.enum';
import { CodeItemType } from 'src/app/models/finance/code/code-item-type.enum';
import Code from 'src/app/models/finance/code/code.model';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { LocalizedLabel } from 'src/app/sharedkernal/localized-label';
import { ConfirmDialogComponent, ConfirmDialogComponentData } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';

@Directive()
export class AlphaComponent {
    sheetQuestionType = DocumentItemViewType.Form;
    searchQuestionType = DocumentItemViewType.Search;
    dialogQuestionType = DocumentItemViewType.Dialog;
    similarityQuestionType = DocumentItemViewType.Similarity;

    sheetTypeBank = SheetType.Bank;
    sheetTypeExam = SheetType.Exam;


    private _tokenData: UserTokenData;
    get tokenData(): UserTokenData {
        if (this._tokenData == null) this._tokenData = this.localStorageService.getTokenData();
        return this._tokenData;
    }

    constructor(
        protected localStorageService?: LocalStorageService,
        protected logz?: AbstractLogger,
        protected dialog?: MatDialog
    ) {
    }

    isAdmin() {
        return this.tokenData.role == RolesNames.Admin;
    }
    isSupervisorOrAbove() {
        return this.tokenData.role == RolesNames.Admin || this.tokenData.role == RolesNames.Supervisor;
    }
    isWriterOrAbove() {
        return this.isSupervisorOrAbove() || this.tokenData.role == RolesNames.Writer;
    }

    logError(error: any) {
        console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)) + ' - ' + JSON.stringify(this.tokenData));
        this.logz.error(JSON.stringify(error, Object.getOwnPropertyNames(error)) + ' - ' + JSON.stringify(this.tokenData));
    }

    ngOnInit(): void {
    }

    sizeOptions = [25, 50, 100, 500];
    pageSizeOptions(currentPage: number, totalItemsCount: number) {
        return [...this.sizeOptions, Number(currentPage), Number(totalItemsCount)]
            .filter(c => c <= totalItemsCount && c <= 500)
            .filter(this.onlyUniquePages)
            .sort((a, b) => a - b);
    }

    onlyUniquePages(value, index, self) {
        return self.indexOf(value) === index && value > 0;
    }

   

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };

    scrollToBottom(): void {
        setTimeout(() => {
            window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight })
        }, 100);
    }

    confirmBeforeAction(action: () => void, message?: string) {
        let data = new ConfirmDialogComponentData();
        if (message)
            data.message = message;
        this.dialog.open(ConfirmDialogComponent, {
            ariaLabel: '',
            data: data
        }).afterClosed().subscribe(dialogResult => {
            if (dialogResult)
                action();
        });
    }

    getApiUrl(){
        return environment.apiUrl;
    }


}
