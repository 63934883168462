import { _isNumberValue } from '@angular/cdk/coercion';
import { HttpParams } from '@angular/common/http';

export class ModelTools {
    static objectToHttpParams(obj: any): HttpParams {
        let httpParams = new HttpParams();
        if (!obj) return httpParams;
        Object.keys(obj).forEach(function (key) {
            if (obj[key] || obj[key] == false) {
                if (obj[key] !== '')
                    httpParams = httpParams.append(key, obj[key]);
            }
        });
        return httpParams;
    }

    static fixObjectFromQueryParams(obj) {
        let newObj = new Object();
        Object.keys(obj).forEach(function (key) {
            if (obj[key] == 'true' || obj[key] == 'false') {
                newObj[key] = obj[key] == 'true';
            }
            else if (_isNumberValue(obj[key])) {
                newObj[key] = Number(obj[key]);
            }
            else
                newObj[key] = obj[key];
        });
        return newObj;
    }
}