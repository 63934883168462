import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { LocalStorageService } from './storage/local-storage.service';
import { TokenSevice } from './auth/token.service';
import { AccountService } from './auth/accounts.service';
import { AuthenticationService } from './auth/authentication.service';
import { TokenInterceptor } from './http/token.interceptor';
import { SnackbarService } from './ui/snack-bar.service';
import { ErrorInterceptor } from './http/error.interceptor';
import { ValidationService } from './ui/validation.service';
import { ContentAdminService } from './auth/content-admin.service';
import { GeneralService } from './general/general.service';
import { ProductsService } from './products.service';
import { SectionsBrandsService } from './sections-brands.service';
import { SlidersService } from './sliders.service';
import { ImagesService } from './images.service';
import { TagsService } from './tags.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        LocalStorageService
        , TokenSevice
        , AccountService
        , AuthenticationService
        , ValidationService
        , GeneralService
        , ProductsService
        , TagsService
        , SectionsBrandsService
        , SlidersService
        , ImagesService
        , ContentAdminService
        , SnackbarService
        , { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
        , { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})
export class ServicesModule { }