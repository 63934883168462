import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {

    }

    success(message: string = 'Done', stuck: boolean = false) {
        this.snackBar.open(message, stuck ? 'Hide' : '', { panelClass: 'snack-success', verticalPosition: 'top', horizontalPosition: 'right', duration: stuck ? null : 3000 });
    }

    error(message: string = 'An error occured, contact the support', stuck: boolean = false) {
        this.snackBar.open(message, stuck ? 'Hide' : '', { panelClass: 'snack-error', verticalPosition: 'top', horizontalPosition: 'right', duration: stuck ? null : 6000 });
    }

    offline() {
        this.snackBar.open('Trying to reconnect....', null, { panelClass: 'snack-error', verticalPosition: 'top', horizontalPosition: 'right', duration: null });
    }

    online() {
        this.success('Connection has been restored!');
    }

    handleError(error: any, stuck: boolean = false) {
        console.log(error);
        let message: string = '';
        let errorObject = error.error ?? error;
        if (errorObject.enumResult == 1)
            message = 'Invalid sent data, contact the support';
        else if (errorObject.enumResult == 2)
            message = 'Internal error, contact the support';
        else if (errorObject.enumResult == 3)
            message = 'Item not found, contact the support';
        else if (errorObject.status >= 400 && errorObject.status < 500) {
            message = 'Internal error, contact the support';
        }
        else if (errorObject.status >= 500) {
            message = 'Internal server error, contact the support';
        }
        else if (errorObject.enumResult === 0)
            return;
        else if (error.status === 0)
            message = 'Error, check your internet connection then try again';
        else
            message = 'Unknown error';


        if (errorObject.errorMessages)
            message = errorObject.errorMessages + ' - ' + message;
        this.snackBar.open(message, stuck ? 'Hide' : '', { panelClass: 'snack-error', verticalPosition: 'top', horizontalPosition: 'right', duration: stuck ? null : 6000 });
    }
    warning(message: string, stuck: boolean = false) {
        this.snackBar.open(message, stuck ? 'Hide' : '', { panelClass: 'snack-warning', verticalPosition: 'top', horizontalPosition: 'right', duration: stuck ? null : 6000 });
    }
}
