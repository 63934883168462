import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

export class BetaService {

    protected getJsonHttpHeaders(): HttpHeaders {
        return new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    protected getPaginationRequestParams(query: string
        , pageNumber: number
        , pageSize: number
        , orderBy: string
        , orderType: string): HttpParams {
        return new HttpParams()
            .append("query", query )
            .append("pageNumber", pageNumber.toString())
            .append("pageSize", pageSize.toString())
            .append("orderBy", orderBy )
            .append("orderType", orderType );
    }
}