import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UserTokenData from '../../models/accounts/user-token-data.model';
import Login from '../../models/accounts/login.model';
import ViewUser from '../../models/accounts/view-user.model';
import DataOperationResult from '../../models/base/data-operation-result';
import { GenericLoginResult } from '../../models/base/results/generic-login-result.enum';
import { environment } from '../../../environments/environment';
import { TokenSevice } from './token.service';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import { PagedTable } from 'src/app/models/base/paged-table';
import { BetaService } from '../base/beta.service';
import UserFilter from 'src/app/models/accounts/user-filter';
import { defaultPageIndex, defaultPageSize } from 'src/app/sharedkernal/constants';
import { ModelTools } from 'src/app/models/base/model-tools';
import OperationResult from 'src/app/models/base/operation-result';
import SaveTermUsers from 'src/app/models/accounts/save-term-user.model';

@Injectable()
export class ContentAdminService extends BetaService {
    static controllerName = "content-admin";
    constructor(private httpClient: HttpClient
        , private tokenSevice: TokenSevice) {
        super();
    }

    getTermUsers(termId: string)
        : Promise<DataOperationResult<GenericOperationResult, Array<string>>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Array<string>>>
                (`${environment.apiUrl}${ContentAdminService.controllerName}/term-users`, {
                    params: { termId: termId },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }

    saveTermUsers(saveTermUsers: SaveTermUsers): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('put', `${environment.apiUrl}${ContentAdminService.controllerName}/term-users`,
                    {
                        body: saveTermUsers
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

}