import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
//APP Modules
import { GuardsModule } from './guards/guards.module';

import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AngularLogzIoModule, LogzioOptions, AbstractLogger, AngularLogzIoService } from 'angular-logz-io';
import { MatPaginatorIntl } from '@angular/material/paginator';
import CustomPaginator from './sharedkernal/custom-paginator';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        HttpClientModule,
        AppRoutingModule,
        RouterModule,
        GuardsModule,
        MatSnackBarModule,
        AngularLogzIoModule,
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
        { provide: LogzioOptions, useValue: { token: 'FCChqqaJsMdQTmwfsILsXoaIlLdBPBPj' } },
        { provide: AbstractLogger, useClass: AngularLogzIoService },
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
