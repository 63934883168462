import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataOperationResult from 'src/app/models/base/data-operation-result';
import OperationResult from 'src/app/models/base/operation-result';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import { environment } from 'src/environments/environment';
import { BetaService } from './base/beta.service';
import Tag from '../models/tag/tag.model';

@Injectable({
    providedIn: 'root'
})
export class TagsService extends BetaService {
    static controllerName = 'tags';
    constructor(private httpClient: HttpClient) {
        super();
    }

    getTags(): Promise<DataOperationResult<GenericOperationResult, Array<Tag>>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Array<Tag>>>
                (`${environment.apiUrl}${TagsService.controllerName}/`,
                    {
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }


    saveTag(tag: Tag): Promise<DataOperationResult<GenericOperationResult, string>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('post', `${environment.apiUrl}${TagsService.controllerName}`,
                    {
                        body: tag
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }


    deleteTag(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${TagsService.controllerName}`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
}