import { MatPaginatorIntl } from "@angular/material/paginator";

export default function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = 'Rows per page';
    customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
        //return ((page * pageSize) + 1) + ' - ' + ((page + 1) * pageSize) + ' من ' + length;
        return 'page : ' + page + ' of ' + Math.trunc(length / pageSize);
    };
    return customPaginatorIntl;
}