import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UserTokenData from '../../models/accounts/user-token-data.model';
import Login from '../../models/accounts/login.model';
import ViewUser from '../../models/accounts/view-user.model';
import DataOperationResult from '../../models/base/data-operation-result';
import { GenericLoginResult } from '../../models/base/results/generic-login-result.enum';
import { environment } from '../../../environments/environment';
import { TokenSevice } from './token.service';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import { PagedTable } from 'src/app/models/base/paged-table';
import { BetaService } from '../base/beta.service';
import UserFilter from 'src/app/models/accounts/user-filter';
import { defaultPageIndex, defaultPageSize } from 'src/app/sharedkernal/constants';
import { ModelTools } from 'src/app/models/base/model-tools';
import OperationResult from 'src/app/models/base/operation-result';

@Injectable()
export class AccountService extends BetaService {
    static controllerName = "accounts";
    constructor(private httpClient: HttpClient
        , private tokenSevice: TokenSevice) {
        super();
    }

    getUserDetails(id: string)
        : Promise<DataOperationResult<GenericOperationResult, ViewUser>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, ViewUser>>
                (`${environment.apiUrl}${AccountService.controllerName}/user-details`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
    getUsers(userFilter: UserFilter, pagedResult: boolean = true): Promise<DataOperationResult<GenericOperationResult, PagedTable<ViewUser>>> {
        if (pagedResult) {
            if (!userFilter.pageIndex) userFilter.pageIndex = defaultPageIndex;
            if (!userFilter.pageSize) userFilter.pageSize = defaultPageSize;
        }

        return new Promise((resolve, reject) => {

            this.httpClient.get<DataOperationResult<GenericOperationResult, PagedTable<ViewUser>>>
                (`${environment.apiUrl}${AccountService.controllerName}/users`,
                    {
                        params: ModelTools.objectToHttpParams(userFilter),
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }

    saveUser(user: ViewUser): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('put', `${environment.apiUrl}${AccountService.controllerName}/save-user`,
                    {
                        body: user
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

    deleteUser(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${AccountService.controllerName}/`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
}