import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
// language list
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TokenSevice } from './services/auth/token.service';
import { AlphaComponent } from './ui/base/alpha-component/alpha-component';
import { AbstractLogger } from 'angular-logz-io';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { SnackbarService } from './services/ui/snack-bar.service';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './services/auth/authentication.service';
import { Keys } from './sharedkernal/keys';
import { GeneralService } from './services/general/general.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends AlphaComponent implements OnInit, OnDestroy {
    private unsubscribe: Subscription[] = [];

    private dataFilled = new BehaviorSubject<boolean>(false);
    public dataFilled$ = this.dataFilled.asObservable();

    constructor(
        private splashScreenService: SplashScreenService,
        private tokenService: TokenSevice,
        private generalService: GeneralService,
        private router: Router,
        private authenticationService: AuthenticationService,
        protected localStorageService: LocalStorageService,
        protected logz: AbstractLogger,
        private snackBarService: SnackbarService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(localStorageService, logz);
        this.createOnline$().subscribe(isOnline => {
            if (!isOnline)
                snackBarService.offline();
            else if (this.dataFilled.value)
                snackBarService.online();
        });
    }

    ngOnInit() {
        this.subscribeHideEvent();
        if (this.tokenService.isAuthenticated()) {
            this.generalService.getLayoutData().then(result => {
                this.localStorageService.writeLayoutData(result.entity);
                this.hideSplash();
                this.dataFilled.next(true);
            }).catch(error => {
                if (error == 'Unauthorized' || [401, 403].includes(error.status)) {
                    this.authenticationService.logout();
                    this.router.navigate(['/auth/login'], {
                        queryParams: {
                            redirectTo: document.location.pathname
                        }
                    });
                    this.hideSplash();
                    this.dataFilled.next(true);
                }
                this.logError(error);
                this.snackBarService.error("We couldn't reach the server, please try to refresh the page or contact the support ", true);
            }).finally(() => {
            });
        }
        else {
            this.dataFilled.next(true);
        }

    }



    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }

    subscribeHideEvent() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && this.dataFilled.value) {
                this.hideSplash();
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    hideSplash() {
        this.splashScreenService.hide();
        setTimeout(() => {
            document.body.classList.add('page-loaded');
        }, 500);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
