import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { TokenSevice } from '../auth/token.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../ui/snack-bar.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, 
        private snackbarService: SnackbarService,
        private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status)) {
                this.snackbarService.error('ليس لديك صلاحية للقيام بهذه العملية');
                
            }

            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(err);
            return throwError(err);
        }))
    }
}