import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataOperationResult from 'src/app/models/base/data-operation-result';
import OperationResult from 'src/app/models/base/operation-result';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import { environment } from 'src/environments/environment';
import { BetaService } from './base/beta.service';
import Section from '../models/sections-brands/section.model';
import Subsection from '../models/sections-brands/subsection.model';
import Brand from '../models/sections-brands/brand.model';

@Injectable({
    providedIn: 'root'
})
export class SectionsBrandsService extends BetaService {
    static controllerName = 'sections-brands';
    constructor(private httpClient: HttpClient) {
        super();
    }

    getSections(): Promise<DataOperationResult<GenericOperationResult, Array<Section>>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Array<Section>>>
                (`${environment.apiUrl}${SectionsBrandsService.controllerName}/sections`,
                    {
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }

    getSubsections(): Promise<DataOperationResult<GenericOperationResult, Array<Subsection>>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Array<Subsection>>>
                (`${environment.apiUrl}${SectionsBrandsService.controllerName}/subsections`,
                    {
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }

    getBrands(): Promise<DataOperationResult<GenericOperationResult, Array<Brand>>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Array<Brand>>>
                (`${environment.apiUrl}${SectionsBrandsService.controllerName}/brands`,
                    {
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }

    saveSection(section: Section): Promise<DataOperationResult<GenericOperationResult, string>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('post', `${environment.apiUrl}${SectionsBrandsService.controllerName}/save-section`,
                    {
                        body: section
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

    saveSubsection(subsection: Subsection): Promise<DataOperationResult<GenericOperationResult, string>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('post', `${environment.apiUrl}${SectionsBrandsService.controllerName}/save-subsection`,
                    {
                        body: subsection
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

    saveBrand(brand: Brand): Promise<DataOperationResult<GenericOperationResult, string>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('post', `${environment.apiUrl}${SectionsBrandsService.controllerName}/save-brand`,
                    {
                        body: brand
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

    deleteSection(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${SectionsBrandsService.controllerName}/section`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
    deleteSubsection(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${SectionsBrandsService.controllerName}/subsection`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
    deleteBrand(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${SectionsBrandsService.controllerName}/brand`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
}