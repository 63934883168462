import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataOperationResult from 'src/app/models/base/data-operation-result';
import OperationResult from 'src/app/models/base/operation-result';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import { environment } from 'src/environments/environment';
import { PagedTable } from 'src/app/models/base/paged-table';
import { defaultPageIndex, defaultPageSize } from 'src/app/sharedkernal/constants';
import { ModelTools } from 'src/app/models/base/model-tools';
import { BetaService } from './base/beta.service';
import ProductsFilter from '../models/products/products-filter.model';
import Product from '../models/products/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductsService extends BetaService {
    static controllerName = 'products';
    constructor(private httpClient: HttpClient) {
        super();
    }
    getProduct(id: string):
        Promise<DataOperationResult<GenericOperationResult, Product>> {

        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, Product>>
                (`${environment.apiUrl}${ProductsService.controllerName}/product`,
                    {
                        params: new HttpParams().append('id', id),
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }

    getProducts(productsFilter: ProductsFilter, pagedResult: boolean = true):
        Promise<DataOperationResult<GenericOperationResult, PagedTable<Product>>> {

        if (pagedResult) {
            if (!productsFilter.pageIndex) productsFilter.pageIndex = defaultPageIndex;
            if (!productsFilter.pageSize) productsFilter.pageSize = defaultPageSize;

        }

        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, PagedTable<Product>>>
                (`${environment.apiUrl}${ProductsService.controllerName}/products`,
                    {
                        params: ModelTools.objectToHttpParams(productsFilter),
                        headers: this.getJsonHttpHeaders()
                    }
                ).subscribe(resolve, reject);
        });
    }

    actionProduct(product: Product): Promise<DataOperationResult<GenericOperationResult, string>> {
        return new Promise((resolve, reject) => {
            this.httpClient.request<DataOperationResult<GenericOperationResult, string>>
                ('post', `${environment.apiUrl}${ProductsService.controllerName}`,
                    {
                        body: product
                        , headers: this.getJsonHttpHeaders()
                    })
                .subscribe(resolve, reject);
        });
    }

    deleteProduct(id: string): Promise<OperationResult<GenericOperationResult>> {
        return new Promise((resolve, reject) => {
            this.httpClient.delete<OperationResult<GenericOperationResult>>
                (`${environment.apiUrl}${ProductsService.controllerName}`, {
                    params: { id: id },
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }

}