import { NgModule } from '@angular/core';
import { JwtHelperService, JwtModuleOptions, JwtModule } from '@auth0/angular-jwt';
import { CommonModule } from '@angular/common';
import { AuthenticationGuard } from './authentication.guard';
import { ServicesModule } from '../services/services.module';
const JWT_Module_Options: JwtModuleOptions = {
    config: {
        tokenGetter: () => ""
    }
};

@NgModule({
    imports: [
        JwtModule.forRoot(JWT_Module_Options),
        CommonModule,
        ServicesModule
    ],
    providers: [JwtHelperService, AuthenticationGuard]
})
export class GuardsModule { }
