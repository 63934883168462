import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataOperationResult from 'src/app/models/base/data-operation-result';
import { GenericOperationResult } from 'src/app/models/base/results/generic-operation-result.enum';
import LayoutData from 'src/app/models/general/layout/layout-data.model';
import YearModel from 'src/app/models/general/year/year.model';
import { environment } from 'src/environments/environment';
import { BetaService } from '../base/beta.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends BetaService {
    constructor(private httpClient: HttpClient) {
        super();
    }
    static controllerName = 'general';
    getLayoutData(): Promise<DataOperationResult<GenericOperationResult, LayoutData>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, LayoutData>>
                (`${environment.apiUrl}${GeneralService.controllerName}/layout-data`, {
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
    getYears(): Promise<DataOperationResult<GenericOperationResult, YearModel>> {
        return new Promise((resolve, reject) => {
            this.httpClient.get<DataOperationResult<GenericOperationResult, YearModel>>
                (`${environment.apiUrl}${GeneralService.controllerName}/years`, {
                    headers: this.getJsonHttpHeaders()
                }).subscribe(resolve, reject);
        });
    }
}
