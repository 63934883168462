import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    data: ConfirmDialogComponentData;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: ConfirmDialogComponentData,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
        this.data = data ?? new ConfirmDialogComponentData();
        this.dialogRef.updateSize('600vw', '500vw')
    }

    ngOnInit(): void {
    }

}
export class ConfirmDialogComponentData {
    message: string = 'Are you sure about this operation?';
    confirmButtonText: string = 'Yes';
    cancelButtonText: string = 'No';
}