import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree, UrlSegment } from '@angular/router';
import { TokenSevice } from '../services/auth/token.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { SnackbarService } from '../services/ui/snack-bar.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private tokenSevice: TokenSevice,
        private router: Router,
        private snackbarService: SnackbarService,
        protected localStorageService: LocalStorageService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let url: string = state.url;
        return this.checkUserLogin(next, url);
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
    }
    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

    checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
        if (this.tokenSevice.isAuthenticated()) {
            const userRole = this.localStorageService.getTokenData().role;

            if (route.data.roles && route.data.roles.indexOf(userRole) < 0) {
                this.snackbarService.error('ليس لديك الصلاحية لفتح هذه الصفحة');
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }
        else
            this.router.navigate(['/auth/login']);
        return false;
    }
}