import { Injectable } from '@angular/core';
import { Keys } from '../../sharedkernal/keys';

import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../storage/local-storage.service'
@Injectable()
export class TokenSevice {

    constructor(private jwtHelper: JwtHelperService
        , private localStorageService: LocalStorageService) { }

    isAuthenticated(): boolean {
        const token = this.localStorageService.read<string>(Keys.tokenLocalStorageKey);
        return !this.jwtHelper.isTokenExpired(token);
    }

    storeToken(token: string): boolean {
        return this.localStorageService.write(Keys.tokenLocalStorageKey, token);
    }

    removeToken() {
        this.localStorageService.remove(Keys.tokenLocalStorageKey);
    }
    

}