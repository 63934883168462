import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UserTokenData from '../../models/accounts/user-token-data.model';
import Login from '../../models/accounts/login.model';
import DataOperationResult from '../../models/base/data-operation-result';
import { GenericLoginResult } from '../../models/base/results/generic-login-result.enum';
import { environment } from '../../../environments/environment';
import { TokenSevice } from '../auth/token.service';
import { BetaService } from './../base/beta.service';

@Injectable()
export class AuthenticationService extends BetaService {
    static controllerName: string = 'authentication';
    constructor(private httpClient: HttpClient
        , private tokenSevice: TokenSevice) {
        super();
    }

    login(loginData: Login)
        : Promise<DataOperationResult<GenericLoginResult, UserTokenData>> {
        return new Promise((resolve, reject) => {
            this.httpClient.post<DataOperationResult<GenericLoginResult, UserTokenData>>
                (`${environment.apiUrl}${AuthenticationService.controllerName}/login`, loginData, {
                    headers: this.getJsonHttpHeaders()
                }).subscribe((loginResult) => {
                    this.tokenSevice.storeToken(loginResult.entity.token);
                    resolve(loginResult);
                }, reject);
        });
    }

    logout() {
        this.tokenSevice.removeToken();
    }
    

}